.btn {
  display: inline-block !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;

  &:hover {
    color: #0088ca;
  }

  &.focus {
    outline: none !important;
    -webkit-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}


.btn-outline-primary-sm {
  font-family: 'Muli-Bold';
  padding: 7px 30px !important;
  font-size: 13px !important;
  border: solid 1px #0088ca !important;
  background: #0000;
  color: #0088ca !important;
  @extend .btn;

  &:hover {
    background: #0088ca !important;
    color: #fff !important;
  }

  &.active {
    background-color: #0088ca;
    color: #fff !important;
  }

  &:focus {
    outline: none !important;
    -webkit-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
}

.btn-outline-primary-md {
  font-family: 'Muli-Bold';
  padding: 6px 30px !important;
  font-size: 15px !important;
  border: solid 1px #1b438b !important;
  background: #0000;
  color: #1b438b !important;
  @extend .btn;

  &:hover {
    background: #1b438b !important;
    color: #fff !important;
  }

  &:focus {
    outline: none !important;
    -webkit-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }

  &.active {
    background: #1b438b !important;
    color: #fff !important;
    border: solid 1px #1b438b !important;
  }

}

.btn-degrade-lg {
  padding: 10px 40px !important;
  font-size: 18px !important;
  border-radius: 70px !important;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to right, #009e73, #0088ca);
  color: #fff !important;
  font-family: 'Muli-Black';
  @extend .btn;


  &:hover {
    background-image: linear-gradient(to right, #009e73, #0088ca);
    color: #fff;
  }


  // &:focus {
  //   -webkit-box-shadow: none !important;
  //   -ms-box-shadow: none !important;
  //   -o-box-shadow: none !important;
  //   -moz-box-shadow: none !important;
  //   box-shadow: none !important;
  // }

}

.btn-outline-light-sm {
  font-family: 'Muli-Black';
  padding: 7px 30px;
  font-size: 12px;
  border: 1px solid #5F7FAD;
  background: #0000;
  color: #fff;
  @extend .btn;

  &:hover {
    background: #0000;
    color: #fff;
  }

  &.active {
    background: #0000;
    color: #fff;
  }

  // &.focus {
  //   outline: none !important;
  // }
}

.btn-degrade-teal-cerulean {
  padding: 10px 40px !important;
  font-size: 18px !important;
  border-radius: 70px !important;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to right, #009e73, #0088ca) !important;
  color: #fff;
  font-family: 'Muli-Black';
  display: inline-block;
  border-radius: 30px;
  text-transform: uppercase;

  &:hover {
    background-image: linear-gradient(to right, #009e73, #0088ca) !important;
    color: #fff !important;
    box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none !important;
    -webkit-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2);
    color: #fff !important;
  }

  &:visited {
    background-image: linear-gradient(to right, #009e73, #0088ca) !important;
    color: #fff !important;
  }

}

