h1 {
  font-family: 'Muli-Black'!important;
  font-size: 30px!important;
  color: #1b438b!important;
  margin-bottom: 0;
}


h2 {
  font-family: 'Muli-Black'!important;
  font-size: 19px!important;
  color: #0088ca!important;
  margin-bottom: 0;
}

.title-p {
  font-family: 'Muli-Regular';
  font-size: 16px;
  color: #666666;
  margin-bottom: 0;
  padding: 0;
  i{
    font-size: 30px;
  }
}


.duvidas {
  font-family: 'Muli-Regular';
  font-size: 15px;
  color: #666666;
  margin-bottom: 0;
  padding: 0;
  line-height: 1.27;
}



@media (max-width: 480px) {
  h1 {
    font-size: 25px!important;
  }
}