@font-face {
    font-family: 'Roboto-Regular';
    src: url(../../fonts/roboto/robotoregular.woff) format("woff");
    font-weight: 400
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url(../../fonts/roboto/robotomedium.woff) format("woff");
    font-weight: 500
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(../../fonts/roboto/robotobold.woff) format("woff");
    font-weight: 700
}

@font-face {
    font-family: 'Roboto-Black';
    src: url(../../fonts/roboto/robotoblack.woff) format("woff");
    font-weight: 900
}

html, body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: 'Roboto-Regular';
    box-sizing: border-box;
    overflow-x: hidden
}

ul {
    margin: 0;
    padding: 0;
    list-style: none
}

/* section {
    padding: 15px 0
} */

/* h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0
} */

/* 
h1.line, h2.line, h3.line, h4.line {
    position: relative;
    text-align: center;
    margin: 30px auto
}

h1.line span, h2.line span, h3.line span, h4.line span {
    background: #fff;
    padding: 0 15px;
    position: relative;
    z-index: 1
}

h1.line:before, h2.line:before, h3.line:before, h4.line:before {
    background: #ddd;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0
}

hr.space {
    height: 20px;
    border: none;
    padding: 0;
    margin: 0
}

hr.break {
    height: 0;
    clear: both;
    border: none;
    margin: 0
} */

/* figure {
    padding: 4px;
    border: #ddd 1px dotted
}

figure figcaption {
    padding: 8px;
    background-color: #fff;
    border-top: #ddd 1px dotted;
    font-size: 12px;
    color: #999;
    text-align: center
}

.hide-scroll {
    overflow: hidden !important
}

.bg-alfa-bco {
    background: url(../images/pixel-bco.png)
} */

.mbottom {
    margin-bottom: 15px !important
}

.mtop {
    margin-top: 15px !important
}

/* .bullets {
    list-style-type: square;
    margin-left: 15px;
    display: inline-block;
    margin: auto auto auto 15px;
    text-align: left
} */

.bg-cinza, .box-cinza {
    background-color: #f5f5f5
}

.bg-cinza h1.line span, .bg-cinza h2.line span, .bg-cinza h3.line span, .bg-cinza h4.line span, .box-cinza h1.line span, .box-cinza h2.line span, .box-cinza h3.line span, .box-cinza h4.line span {
    background: #f5f5f5
}

.box-cinza {
    padding: 15px
}

.banners-publicidade img {
    margin-bottom: 10px
}

.banners-publicidade a {
    display: block;
    margin-bottom: 10px
}

.banners-publicidade a img {
    margin-bottom: 0
}

*/ .btn {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    white-space: normal;
    height: auto
}

.alert {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    text-align: left;
    margin-bottom: 15px;
    padding: 5px 15px
}

.alert.alert-empty {
    text-align: center;
    background-color: #f5f5f5;
    border: #ddd 1px solid;
    margin: 15px auto;
    color: #999
}

.alert.alert-empty i {
    font-size: 50px;
    margin: auto
}

.alert.alert-empty h2, .alert.alert-empty h3, .alert.alert-empty h4, .alert.alert-empty h5, .alert.alert-empty h6 {
    font-size: 18px
}

.alert.alert-empty.alert-danger {
    color: #a94442
}

.alert.alert-empty.alert-success {
    color: #6db473
}

.alert.alert-empty.alert-warning {
    color: #f0ad4e
}

.alert.alert-empty p {
    color: #666
}

img.img-responsive.center {
    margin: auto
}

.row.space5 {
    margin-left: -5px;
    margin-right: -5px
}

.row.space5 .col-lg-1, .row.space5 .col-lg-10, .row.space5 .col-lg-11, .row.space5 .col-lg-12, .row.space5 .col-lg-2, .row.space5 .col-lg-3, .row.space5 .col-lg-4, .row.space5 .col-lg-5, .row.space5 .col-lg-6, .row.space5 .col-lg-7, .row.space5 .col-lg-8, .row.space5 .col-lg-9, .row.space5 .col-md-1, .row.space5 .col-md-10, .row.space5 .col-md-11, .row.space5 .col-md-12, .row.space5 .col-md-2, .row.space5 .col-md-3, .row.space5 .col-md-4, .row.space5 .col-md-5, .row.space5 .col-md-6, .row.space5 .col-md-7, .row.space5 .col-md-8, .row.space5 .col-md-9, .row.space5 .col-sm-1, .row.space5 .col-sm-10, .row.space5 .col-sm-11, .row.space5 .col-sm-12, .row.space5 .col-sm-2, .row.space5 .col-sm-3, .row.space5 .col-sm-4, .row.space5 .col-sm-5, .row.space5 .col-sm-6, .row.space5 .col-sm-7, .row.space5 .col-sm-8, .row.space5 .col-sm-9, .row.space5 .col-xs-1, .row.space5 .col-xs-10, .row.space5 .col-xs-11, .row.space5 .col-xs-12, .row.space5 .col-xs-2, .row.space5 .col-xs-3, .row.space5 .col-xs-4, .row.space5 .col-xs-5, .row.space5 .col-xs-6, .row.space5 .col-xs-7, .row.space5 .col-xs-8, .row.space5 .col-xs-9 {
    padding-left: 5px;
    padding-right: 5px
}

.row.space10 {
    margin-left: -10px;
    margin-right: -10px
}

.row.space10 .col-lg-1, .row.space10 .col-lg-10, .row.space10 .col-lg-11, .row.space10 .col-lg-12, .row.space10 .col-lg-2, .row.space10 .col-lg-3, .row.space10 .col-lg-4, .row.space10 .col-lg-5, .row.space10 .col-lg-6, .row.space10 .col-lg-7, .row.space10 .col-lg-8, .row.space10 .col-lg-9, .row.space10 .col-md-1, .row.space10 .col-md-10, .row.space10 .col-md-11, .row.space10 .col-md-12, .row.space10 .col-md-2, .row.space10 .col-md-3, .row.space10 .col-md-4, .row.space10 .col-md-5, .row.space10 .col-md-6, .row.space10 .col-md-7, .row.space10 .col-md-8, .row.space10 .col-md-9, .row.space10 .col-sm-1, .row.space10 .col-sm-10, .row.space10 .col-sm-11, .row.space10 .col-sm-12, .row.space10 .col-sm-2, .row.space10 .col-sm-3, .row.space10 .col-sm-4, .row.space10 .col-sm-5, .row.space10 .col-sm-6, .row.space10 .col-sm-7, .row.space10 .col-sm-8, .row.space10 .col-sm-9, .row.space10 .col-xs-1, .row.space10 .col-xs-10, .row.space10 .col-xs-11, .row.space10 .col-xs-12, .row.space10 .col-xs-2, .row.space10 .col-xs-3, .row.space10 .col-xs-4, .row.space10 .col-xs-5, .row.space10 .col-xs-6, .row.space10 .col-xs-7, .row.space10 .col-xs-8, .row.space10 .col-xs-9 {
    padding-left: 10px;
    padding-right: 10px
}

 input, select, textarea {
    width: 100%;
    height: 45px;
    padding: 5px 10px;
    border: 1px solid #ddd;
    color: #999;
    margin-bottom: 10px
} 

input.input-validation-error, select.input-validation-error, textarea.input-validation-error {
    border-bottom-color: #a94442;
    color: #a94442
}

input.input-validation-error::-webkit-input-placeholder, select.input-validation-error::-webkit-input-placeholder, textarea.input-validation-error::-webkit-input-placeholder {
    color: #a94442
}

input.input-validation-error:-moz-placeholder, select.input-validation-error:-moz-placeholder, textarea.input-validation-error:-moz-placeholder {
    color: #a94442
}

input.input-validation-error::-moz-placeholder, select.input-validation-error::-moz-placeholder, textarea.input-validation-error::-moz-placeholder {
    color: #a94442
}

input.input-validation-error:-ms-input-placeholder, select.input-validation-error:-ms-input-placeholder, textarea.input-validation-error:-ms-input-placeholder {
    color: #a94442
}

input:disabled, select:disabled, textarea:disabled {
    background-color: #fff;
    cursor: no-drop
}

input:focus, select:focus, textarea:focus {
    outline: none
}

input:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset
}

textarea {
    height: 125px
}

input[type='submit'], input[type='button'] {
    width: auto;
    margin: auto;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -o-border-radius: 0px;
    border: 1px solid transparent;
    font-weight: bold
}

label {
    width: 100%;
    height: 45px;
    margin-bottom: 10px
}

label input, label select, label textarea {
    margin: 0;
    padding: 0 10px;
    height: 45px;
    font-weight: normal;
    font-family: "Roboto-Medium";
    color: #666
}

label input::-webkit-input-placeholder, label select::-webkit-input-placeholder, label textarea::-webkit-input-placeholder {
    font-size: 15px;
    color: #999;
    font-weight: normal;
    line-height: normal
}

label input:-moz-placeholder, label select:-moz-placeholder, label textarea:-moz-placeholder {
    font-size: 15px;
    color: #999;
    font-weight: normal;
    line-height: normal
}

label input::-moz-placeholder, label select::-moz-placeholder, label textarea::-moz-placeholder {
    font-size: 15px;
    color: #999;
    font-weight: normal;
    line-height: normal
}

/* 
label input:-ms-input-placeholder, label select:-ms-input-placeholder, label textarea:-ms-input-placeholder {
    font-size: 15px;
    color: #999;
    font-weight: normal;
    line-height: normal
}

label input:-moz-placeholder {
    line-height: 45px
}

label input::-moz-placeholder {
    line-height: 45px
}

label select {
    padding: 5px 10px
}

label span {
    display: none
} */

/* 
label.label-embutido {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    padding: 2.5px 10px;
    border: #ddd 1px solid;
    background-color: #fff
}

label.label-embutido span {
    width: 100%;
    height: 15px;
    display: block;
    font-size: 11px;
    font-weight: normal;
    color: #999;
    background-color: #fff
}

label.label-embutido span.btn-edit-input {
    width: auto;
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: #ddd;
    padding: 2px 5px;
    display: block;
    height: auto;
    cursor: pointer
}

label.label-embutido input, label.label-embutido select, label.label-embutido textarea {
    margin: 0 0 5px 0;
    padding: 0;
    border: none;
    height: 20px
}

label.label-embutido input:-moz-placeholder {
    line-height: normal
}

label.label-embutido input::-moz-placeholder {
    line-height: normal
}

label.label-embutido .alert.alert-form {
    margin-top: -15px;
    margin-bottom: 20px
}

label.label-embutido.label-textarea textarea {
    height: 75px;
    padding: 0
}

label.label-embutido.label-disabled {
    background-color: #f5f5f5;
    cursor: not-allowed
}

label.label-embutido.label-disabled span, label.label-embutido.label-disabled input, label.label-embutido.label-disabled select, label.label-embutido.label-disabled textarea {
    background-color: transparent;
    cursor: not-allowed
}

label.label-embutido.label-error {
    border-bottom-color: #a94442
}

label.label-embutido.label-error span {
    color: #a94442
}

label .btn-edit-input {
    width: auto;
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: #ddd;
    padding: 2px 5px;
    display: block;
    height: auto;
    cursor: pointer
}

label.label-upload {
    margin-bottom: 5px;
    width: auto;
    height: auto
}

label.label-upload input[type="file"] {
    display: none
}

label.normal {
    width: 100%;
    height: auto;
    margin-bottom: 0
}

label.label-textarea {
    height: 100px
}

label.label-textarea textarea {
    height: 100px;
    padding: 10px
}

label.label-radiocheck {
    border: none;
    background: none;
    padding: 0;
    width: auto !important;
    height: auto;
    float: left
}

label.label-radiocheck input[type='checkbox'], label.label-radiocheck input[type='radio'] {
    width: auto;
    margin: auto;
    float: left;
    margin-right: 5px
}

label.label-radiocheck.full-label {
    float: none
}

.alert.alert-form {
    margin-top: -10px;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: normal;
    display: none
}

.alert.alert-form.field-validation-error {
    display: block
} */

.toggle-select {
    margin: auto
}

.toggle-select input[type="checkbox"], .toggle-select input[type="radio"] {
    display: none
}

.toggle-select i {
    font-size: 25px;
    color: #999;
    cursor: pointer
}

.toggle-select i.fa-toggle-on, .toggle-select i.fa-toggle-off {
    font-size: 25px !important
}

.toggle-select i.fa-toggle-off {
    color: #999 !important
}

.toggle-select i.fa-toggle-on {
    color: #6db473 !important
}

header {
    width: 100%;
    background-color: #f5f5f5;
    position: inherit;
    top: 0;
    left: 0;
    z-index: 99999;
    min-height: 55px;
    border-bottom: #ddd 1px solid
}

header #barra-institucional {
    background-color: #002052;
    color: #8d93a1;
    height: 45px;
    position: relative;
    z-index: 999
}

header #barra-institucional.acesso-dayconnect .abrir-conta-juridico {
    line-height: 15px!important;
    padding: 10px!important;
    font-size: 11px;
    float: right;
    height: 45px;
    background-color: #0455A7;
    color: #fff!important;
    display: block;
    font-weight: bold;
    margin-top: -7px;
}

header #barra-institucional.acesso-dayconnect .abrir-conta-juridico span {
    display: block;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
}

header #barra-institucional.acesso-dayconnect .abrir-conta {
    line-height: 15px!important;
    padding: 10px!important;
    font-size: 11px;
    float: right;
    height: 45px;
    background-color: #0688c9;
    color: #fff!important;
    display: block;
    font-weight: bold;
}

header #barra-institucional.acesso-dayconnect .abrir-conta span {
    display: block;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
}

header #barra-institucional .links-institucionais ul {
    padding: 0;
    margin: 0;
    display: block
}

header #barra-institucional .links-institucionais ul li {
    float: left;
    font-size: 12px;
    height: 45px;
    line-height: 45px;
    border-right: #334d75 1px solid;
    list-style: none
}

header #barra-institucional .links-institucionais ul li a {
    padding: 0 7px;
    color: #8d93a1;
    display: block
}

header #barra-institucional .links-institucionais ul li:first-child {
    background-color: #ddd
}

header #barra-institucional .links-institucionais ul li:first-child a {
    padding: 0 14px
}

header #barra-institucional .links-institucionais ul li:first-child a i {
    font-size: 25px;
    line-height: 45px;
    float: left;
    margin-right: 5px
}

header #barra-institucional .links-institucionais ul li:last-child {
    border: none
}

header #barra-institucional .links-institucionais ul li:hover a {
    text-decoration: underline
}

header #barra-institucional .links-institucionais .links-institucionais-mob {
    display: none;
    width: auto;
    float: left;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    font-weight: bold;
    background-color: #ddd;
    cursor: pointer
}

header #barra-institucional .links-institucionais .links-institucionais-mob:hover {
    text-decoration: underline
}

header #barra-institucional.login .user-logado {
    float: right
}

header #barra-institucional.login .user-logado .saudacao {
    float: left;
    height: 38px;
    font-size: 14px;
    line-height: 14px;
    color: #f5f5f5;
    padding-top: 7px
}

header #barra-institucional.login .user-logado .saudacao span {
    display: block;
    font-size: 12px
}

header #barra-institucional.login .user-logado form {
    float: left
}

header #barra-institucional.login .user-logado .btnLogout {
    height: 45px;
    line-height: 45px;
    padding: 0 10px;
    margin-left: 10px;
    background-color: #2c466e;
    float: left;
    color: #f5f5f5
}

header #barra-institucional.login .user-logado .btnLogout:hover {
    background-color: #004494;
    cursor: pointer
}

header #barra-institucional.login .user-logado .link-dayconnect {
    float: left;
    background-color: #0688c9;
    color: #fff;
    display: block;
    padding: 0 15px;
    margin-right: 15px;
    height: 45px;
    line-height: 45px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase
}

header #barra-institucional.acesso-dayconnect .links-institucionais {
    float: left
}

header #barra-institucional.acesso-dayconnect .dayconnect {
    float: right;
    padding: 7px 0 0 0
}

header #barra-institucional.acesso-dayconnect .dayconnect .link-dayconnect {
    float: left;
    margin-right: 5px;
    text-transform: uppercase;
    font-size: 12px;
    color: #fff;
    height: 25px;
    line-height: 30px;
    margin-left: 2px
}

header #barra-institucional.acesso-dayconnect .dayconnect .link-dayconnect i {
    font-size: 15px;
    padding: 0 5px 0 0
}

header #barra-institucional.acesso-dayconnect .dayconnect .link-dayconnect-mob {
    display: none;
    position: absolute;
    top: 0;
    right: 15px;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    background-color: #0688c9;
    padding: 0 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: #fff;
    cursor: pointer
}

header #barra-institucional.acesso-dayconnect .dayconnect .link-dayconnect-mob i {
    margin-right: 5px
}

header #barra-institucional.acesso-dayconnect .dayconnect .link-dayconnect-mob:hover {
    text-decoration: underline
}

header #barra-institucional.acesso-dayconnect .dayconnect .form-group {
    float: left;
    margin: 0;
    padding: 0;
    display: block
}

header #barra-institucional.acesso-dayconnect .dayconnect .form-group input, header #barra-institucional.acesso-dayconnect .dayconnect .form-group button {
    float: left;
    height: 27px;
    line-height: 30px;
    margin-left: 2px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0
}

header #barra-institucional.acesso-dayconnect .dayconnect .form-group input {
    width: 38px;
    line-height: normal;
    padding: 0;
    text-align: center;
    margin-bottom: 0;
    font-size: 15px;
    border: none
}

header #barra-institucional.acesso-dayconnect .dayconnect .form-group input[type="submit"] {
    background: #004494;
    border: none;
    padding: 0;
    margin: 0 0 0 2px;
    height: 28px;
    font-size: 14px;
    box-shadow: none;
    line-height: 14px
}

header #barra-institucional.acesso-dayconnect .dayconnect .form-group button {
    line-height: 15px;
    margin-left: 3px
}

header #barra-institucional.acesso-dayconnect .dayconnect .form-group form {
    float: left
}

header #barra-institucional.acesso-dayconnect .dayconnect .duvida {
    float: left;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    margin: 3px 0 0 8px;
    background-color: #8d93a1;
    cursor: pointer
}

header #barra-institucional.acesso-dayconnect .dayconnect label {
    width: auto
}

header #barra-institucional.acesso-dayconnect hr.divisao-login {
    display: none;
    margin: 0 0 8px;
    padding-top: 5px;
    clear: both;
    border: none;
    border-bottom: #ddd 1px solid
}

header #barra-institucional.acesso-dayconnect .title-dayconnect {
    display: none
}

header #barra-institucional.acesso-dayconnect .abrir-conta {
    float: right;
    height: 45px;
    line-height: 45px;
    /* margin-left: 10px; */
    margin-top: -7px;
    background-color: #008BD0;
    color: #fff;
    display: block;
    padding: 0 10px;
    font-weight: bold;
    font-size: 12px
}

#barra-institucional.acesso-dayconnect .dayconnect label {
    color: #fff;
    margin-top: 0px;
    height: 0;
    font-size: 10px!important;
    font-weight: 400!important;
    margin-right: 15px;
}

header #barra-institucional.acesso-dayconnect .abrir-conta span {
    display: block;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
}

header #barra-institucional.acesso-dayconnect .abrir-conta.login {
    margin-left: 5px;
    background-color: #0688c9;
    color: #ddd
}

header #barra-institucional.light {
    background-color: #f5f5f5
}

header #barra-institucional.light .links-institucionais ul li {
    border-right: #fff 1px solid
}

header #barra-institucional.light.login .user-logado .saudacao {
    color: #666
}

header #barra-institucional.light.acesso-dayconnect .dayconnect .link-dayconnect {
    color: #004494
}

header #barra-institucional.light.acesso-dayconnect .dayconnect .form-group input {
    border: #ddd 1px solid
}

header #barra-institucional.light.acesso-dayconnect .dayconnect .form-group input[type="submit"] {
    border: none
}

header .box-logo .logo {
    margin-top: 0;
    /*15px*/
    display: block;
    float: left
}

header .box-logo .logo img {
    width: auto;
    max-height: 40px;
    float: left
}

header .box-logo .logo span {
    border-left: #ddd 1px solid;
    height: 40px;
    display: block;
    float: left;
    width: 90px;
    padding: 10px 0 0 10px;
    margin-left: 10px;
    line-height: 16px;
    font-size: 15px;
    color: #999
}

.select-padding{
    padding-top: 6.5px !important;
    padding-bottom: 6.5px !important;
  }

  header .box-logo .btn-menu-mob {
    display: none;
    width: 50px;
    height: 60px;
    background-color: #f5f5f5;
    color: #2c466e;
    border: 0;
    float: left;
    margin-left: -15px;
    margin-right: 0;
    font-size: 25px
}

header .box-logo.simple {
    text-align: center
}

header .box-logo.simple .logo {
    display: table;
    margin: 10px auto auto;
    float: none
}

header #menu-principal {
    background-color: #f5f5f5;
    border-bottom: #ddd 1px solid
}

header #menu-principal .btn-destaque-menu {
    float: right;
    display: block;
    line-height: 80px;
    padding: 0 20px;
    background-color: #0688c9;
    color: #fff;
    font-weight: bold
}

header #menu-principal .btn-destaque-menu i {
    margin-right: 5px
}

header #menu-principal .usuario-logado {
    background-color: #2c466e;
    float: right
}

header #menu-principal .usuario-logado .boas-vindas {
    border-left: #2c466e 1px solid;
    height: 55px;
    padding: 8px 15px 0 15px;
    float: left;
    color: #8d93a1
}

header #menu-principal .usuario-logado .boas-vindas span {
    font-size: 11px
}

header #menu-principal .usuario-logado .boas-vindas h5 {
    font-size: 12px;
    line-height: 13px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase
}

header #menu-principal .usuario-logado .btn-sair {
    float: left;
    line-height: 55px;
    padding: 0 15px;
    background-color: #004494;
    color: #fff
}

header #menu-principal nav {
    margin-top: 0;
    height: 80px;
    border-left: #ddd 1px solid;
    border-right: #fff 1px solid;
    float: right
}

header #menu-principal nav .links-nav {
    float: left;
    display: block;
    height: 100%;
    padding: 0 20px;
    border-left: #fff 1px solid;
    border-right: #ddd 1px solid;
    margin: auto;
    line-height: 80px;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    color: #666;
    cursor: pointer;
    position: relative
}

header #menu-principal nav .links-nav.submenu a {
    color: #666
}

header #menu-principal nav .links-nav.submenu ul {
    display: none;
    background-color: #ddd;
    position: absolute;
    margin: 0;
    width: 250px;
    left: 0
}

header #menu-principal nav .links-nav.submenu ul li {
    border: none;
    border-bottom: #ccc 1px solid;
    border-top: #f5f5f5 1px solid;
    width: 100%;
    background-color: #ddd;
    display: block;
    line-height: normal;
    text-align: left;
    height: 45px
}

header #menu-principal nav .links-nav.submenu ul li a {
    text-align: left;
    height: 100%;
    width: 100%;
    line-height: 45px;
    padding: 0 15px;
    color: #666;
    background: none;
    clear: both;
    display: block
}

header #menu-principal nav .links-nav.submenu ul li a:hover {
    text-decoration: underline
}

header #menu-principal nav .links-nav.submenu.right ul {
    left: auto;
    right: 0
}

header #menu-principal nav .links-nav.submenu:hover ul {
    display: block
}

header #menu-principal nav .links-nav:hover {
    background-color: #ddd;
    text-decoration: none
}

header #menu-principal nav .links-nav.linha-dupla {
    line-height: 15px;
    padding-top: 25px
}

header #menu-principal nav .links-nav.linha-dupla span {
    display: block
}

header #menu-principal.full-menu nav .links-nav {
    position: static
}

header #menu-principal.full-menu nav .links-nav.submenu .submenu-full {
    display: none;
    background-color: #ddd;
    width: 100%;
    height: auto;
    left: 0;
    position: absolute;
    padding: 15px 0
}

header #menu-principal.full-menu nav .links-nav.submenu .submenu-full ul {
    display: block;
    position: relative;
    width: 100%
}

header #menu-principal.full-menu nav .links-nav.submenu .submenu-full ul li {
    border: none;
    width: 100%;
    background: none;
    height: auto
}

header #menu-principal.full-menu nav .links-nav.submenu .submenu-full ul li a {
    width: 100%;
    line-height: normal;
    padding: 2px 0;
    font-weight: normal;
    text-transform: none;
    font-size: 14px
}

header #menu-principal.full-menu nav .links-nav.submenu .submenu-full ul li a:hover {
    text-decoration: underline
}

header #menu-principal.full-menu nav .links-nav.submenu .submenu-full ul li.title-submenu {
    font-size: 15px;
    border-bottom: #f5f5f5 1px solid;
    padding-bottom: 5px;
    margin-bottom: 5px
}

header #menu-principal.full-menu nav .links-nav.submenu .submenu-full ul li.title-submenu a {
    color: #002052;
    text-transform: uppercase;
    font-weight: bold
}

header #menu-principal.full-menu nav .links-nav.submenu:hover .submenu-full {
    display: block
}

header.dark {
    background-color: #002052
}

header.dark #menu-principal {
    background-color: #002052;
    border: none
}

header.dark #menu-principal nav {
    border: none
}

header.dark #menu-principal nav .links-nav {
    border: none;
    border-left: #2c466e 1px solid;
    color: #8d93a1
}

header.dark #menu-principal nav .links-nav:first-child {
    border-left: none
}

header.dark #menu-principal nav .links-nav a {
    color: #8d93a1
}

/* 
#wrap {
    margin-top: 125px;
    min-height: 72vh
}

#wrap .swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 1
}

#wrap .swiper-container .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

#wrap .swiper-container .swiper-wrapper .swiper-slide {
    float: left;
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0;
    width: 100%;
    height: 100%
}

#wrap .swiper-container .swiper-wrapper .swiper-slide .swiper-bg-banner, #wrap .swiper-container .swiper-wrapper .swiper-slide .swiper-bg-banner-secundary {
    width: 100%;
    height: 100%;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover
}

#wrap .swiper-container .swiper-wrapper .swiper-slide .swiper-legend {
    border: 0;
    padding: 0;
    margin: 0;
    color: #fff
}

#wrap .swiper-container .swiper-wrapper .swiper-slide .swiper-legend h2 {
    font-size: 23px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    margin: 0
}

#wrap .swiper-container .swiper-wrapper .swiper-slide .swiper-legend p {
    font-weight: normal;
    font-size: 14px
}

#wrap .swiper-container .swiper-wrapper .swiper-slide .swiper-legend.swiper-legend-bottom {
    position: absolute;
    bottom: 30px
}

#wrap .swiper-container .swiper-button-prev, #wrap .swiper-container .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 60px;
    line-height: 60px;
    margin-top: -30px;
    z-index: 10;
    cursor: pointer;
    text-align: center;
    color: #666
}

#wrap .swiper-container .swiper-button-prev i, #wrap .swiper-container .swiper-button-next i {
    width: 100%;
    display: block;
    font-size: 70px;
    line-height: 60px;
    font-weight: normal
}

#wrap .swiper-container .swiper-button-prev:hover, #wrap .swiper-container .swiper-button-next:hover {
    color: #999
}

#wrap .swiper-container .swiper-button-prev.swiper-button-white, #wrap .swiper-container .swiper-button-next.swiper-button-white {
    color: #fff
}

#wrap .swiper-container .swiper-button-prev.swiper-button-white:hover, #wrap .swiper-container .swiper-button-next.swiper-button-white:hover {
    color: #ddd
}

#wrap .swiper-container .swiper-button-prev.swiper-button-disabled, #wrap .swiper-container .swiper-button-next.swiper-button-disabled {
    opacity: 0.25;
    cursor: auto;
    pointer-events: none
}

#wrap .swiper-container .swiper-button-next {
    right: 0
}

#wrap .swiper-container .swiper-pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: table;
    margin: auto;
    text-align: center;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 9999
}

#wrap .swiper-container .swiper-pagination.swiper-pagination-hidden {
    opacity: 0
}

#wrap .swiper-container .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 5px;
    display: inline-block;
    background: #fff;
    opacity: 0.2
}

#wrap .swiper-container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1;
    border: #fff 5px solid
}

#wrap .swiper-container .swiper-pagination.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer
}

#wrap .swiper-container .swiper-pagination.swiper-pagination-clickable .swiper-pagination-bullet.swiper-pagination-bullet-active {
    cursor: default
}

#wrap .swiper-container .full-container {
    position: absolute;
    width: 100%;
    top: 0
}

#wrap .swiper-container .full-container .row {
    position: relative;
    height: 100%
}

#wrap .swiper-container .full-container .row div {
    height: 100%
} */

/* #wrap .swiper-container .full-container .row .swiper-legend {
    padding: 20px 15px;
    position: absolute;
    top: 50%;
    background: url(../images/pixel-opacity-60.png)
} */

/* 
#wrap .swiper-container .full-container .row .swiper-legend h2 {
    font-size: 23px;
    text-transform: uppercase;
    font-weight: bold
}

#wrap .swiper-container .full-container .row .swiper-legend p {
    font-weight: normal;
    font-size: 14px
}

#wrap .swiper-container.hight-fixed .swiper-wrapper .swiper-slide {
    height: 420px
}

#wrap .swiper-container.hight-fixed .swiper-wrapper .swiper-slide .swiper-hight-fixed {
    height: 420px
}

#wrap .bloco-fixo-banner {
    position: relative;
    padding: 0
}

#wrap .bloco-fixo-banner .camada-fixa-banner {
    width: 100%;
    position: absolute;
    top: 0
}

#wrap .login-cadastro {
    margin: 30px auto
}

#wrap .login-cadastro .header-login-cadastro {
    text-align: center;
    padding: 15px;
    background-color: #fff;
    margin-bottom: 15px;
    border-bottom: #ddd 2px solid
}

#wrap .login-cadastro .content-login-cadastro {
    padding: 15px 0
}

#wrap .login-cadastro .content-login-cadastro h2 {
    font-size: 14px;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: #ddd 1px dotted;
    color: #666
}

#wrap .login-cadastro .footer-login-cadastro {
    text-align: center;
    margin-top: 15px;
    padding-top: 15px;
    border-top: #ddd 1px solid
}

#wrap .login-cadastro .footer-login-cadastro p {
    padding: 5px 0
}

#wrap .login-cadastro .footer-login-cadastro .btn {
    margin: 5px 0
}

#wrap .login-cadastro.box-medio {
    max-width: 768px
}

#wrap .login-cadastro.box-pequeno {
    max-width: 400px
}

#wrap .steps {
    margin: 0;
    width: 100%;
    height: 35px;
    list-style: none;
    z-index: 0;
    display: block
}

#wrap .steps li {
    float: left;
    padding: 5px 15px;
    width: 10%;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 25px;
    position: relative;
    color: #999;
    margin-left: 0.5%;
    background-color: #f5f5f5
}

#wrap .steps li span {
    display: none
}

#wrap .steps li:first-child {
    margin-left: 0
}

#wrap .steps li:last-child.active {
    float: right
}

#wrap .steps li.active {
    width: auto;
    background-color: #ddd;
    color: #666
}

#wrap .steps li.active span {
    display: inline
}

#wrap .steps li.active:before {
    border-color: transparent transparent transparent #0083cc
}

#wrap .steps li.active:before span {
    display: inline
}

#wrap .steps li:last-child {
    border: none
}

#wrap .steps li:last-child:after, #wrap .steps li:last-child:before {
    display: none
}

#wrap .passo-a-passo {
    color: #666;
    text-align: center
}

#wrap .passo-a-passo i {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 35px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #f5f5f5;
    display: block;
    margin: auto;
    color: #999;
    font-style: normal
}

#wrap .passo-a-passo h4 {
    text-transform: uppercase;
    margin: 10px 0 3px 0
}

#wrap .passo-a-passo p {
    line-height: 13px;
    font-size: 13px
}

#wrap .passo-a-passo:hover {
    color: #002052
}

#wrap .passo-a-passo:hover i {
    color: #fff;
    background-color: #002052
}

#wrap .accordion-panel {
    margin: 30px auto;
    padding: 15px;
    background-color: #f5f5f5;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px
}

#wrap .accordion-panel .accordion {
    border: #f5f5f5 1px solid;
    color: #666;
    margin-bottom: 10px
}

#wrap .accordion-panel .accordion .accordion-title {
    color: #2c466e;
    position: relative;
    background-color: #f5f5f5;
    cursor: pointer;
    border-bottom: #ddd 1px solid;
    border-left: #002052 5px solid
}

#wrap .accordion-panel .accordion .accordion-title h2, #wrap .accordion-panel .accordion .accordion-title h3, #wrap .accordion-panel .accordion .accordion-title h4 {
    padding: 10px 35px 10px 15px;
    margin: 0;
    font-size: 15px;
    text-transform: uppercase;
    font-family: "Roboto-Medium"
}

#wrap .accordion-panel .accordion .accordion-title i {
    position: absolute;
    font-size: 16px;
    color: #999;
    top: 10px;
    right: 10px;
    display: none
}

#wrap .accordion-panel .accordion .accordion-title i.fa-chevron-down {
    display: block
}

#wrap .accordion-panel .accordion .accordion-content {
    display: none;
    padding: 15px
}

#wrap .accordion-panel .accordion .accordion-content p {
    line-height: 15px;
    font-size: 14px;
    margin: 5px auto
}

#wrap .accordion-panel .accordion .accordion-content h4 {
    border-bottom: #ddd 1px solid;
    padding: 8px 0;
    font-size: 14px;
    font-weight: bold;
    margin: 8px auto;
    font-family: "Roboto-Medium"
}

#wrap .accordion-panel .accordion.open .accordion-title i {
    display: none
}

#wrap .accordion-panel .accordion.open .accordion-title i.fa-chevron-up {
    display: block
}

#wrap .accordion-panel .accordion.open .accordion-content {
    display: block
}

#wrap .accordion-menu-filters {
    padding: 15px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    text-align: center
}

#wrap .accordion-menu-filters .main-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    background-color: #ddd;
    line-height: 80px;
    font-size: 45px;
    color: #fff
}

#wrap .accordion-menu-filters h2 {
    text-transform: uppercase;
    font-size: 18px
}

#wrap .accordion-menu-filters ul {
    margin: 15px auto auto;
    text-align: left
}

#wrap .accordion-menu-filters ul li {
    border-top: #ddd 1px solid
}

#wrap .accordion-menu-filters ul li a {
    display: block;
    padding: 10px;
    color: #666
}

#wrap .content-accordion {
    display: none
}

#wrap .content-accordion.open {
    display: block
} */

/* .loader {
    color: #fff;
    position: fixed;
    box-sizing: border-box;
    left: -9999px;
    top: -9999px;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: 999999;
    display: none
}

.loader:after, .loader:before {
    box-sizing: border-box
}

.loader.is-active {
    background-color: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block
}

.loader[data-text]:before {
    position: fixed;
    left: 0;
    top: 50%;
    color: currentColor;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    width: 100%;
    font-size: 14px
}

.loader[data-text='']:before {
    content: 'Loading'
}

.loader[data-text]:not([data-text='']):before {
    content: attr(data-text)
}

.loader[data-text][blink]:before {
    animation: blink 1s linear infinite alternate
}

@keyframes rotation {
    from {
        transform: rotate(0)
    }
    to {
        transform: rotate(359deg)
    }
}

@keyframes blink {
    from {
        opacity: .5
    }
    to {
        opacity: 1
    }
}

.loader-default[data-text]:before {
    top: calc(50% - 63px)
}

.loader-default:after {
    content: '';
    position: fixed;
    width: 48px;
    height: 48px;
    border: solid 8px #fff;
    border-left-color: transparent;
    border-radius: 50%;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    animation: rotation 1s linear infinite
}

.loader-default[half]:after {
    border-right-color: transparent
}

@keyframes moveBar {
    from {
        background-position: 0 0
    }
    to {
        background-position: 20px 20px
    }
}

@keyframes corners {
    6% {
        width: 60px;
        height: 15px
    }
    25% {
        width: 15px;
        height: 15px;
        left: calc(100% - 15px);
        top: 0
    }
    31% {
        height: 60px
    }
    50% {
        height: 15px;
        top: calc(100% - 15px);
        left: calc(100% - 15px)
    }
    56% {
        width: 60px
    }
    75% {
        width: 15px;
        left: 0;
        top: calc(100% - 15px)
    }
    81% {
        height: 60px
    }
} */

/* footer {
    width: 100%;
    background-color: #f5f5f5
}

footer .barra-footer {
    background-color: #002052;
    height: auto
}

footer .barra-footer .menu-footer {
    float: right;
    width: auto
}

footer .barra-footer .menu-footer li {
    float: left;
    border-left: #2c466e 1px solid
}

footer .barra-footer .menu-footer li a {
    color: #8d93a1;
    display: block;
    padding: 0 10px;
    line-height: 55px
}

footer .barra-footer .menu-footer li:first-child {
    border-left: none
}

footer .barra-footer .menu-footer li.btn-mapa-site {
    background-color: #004494;
    color: #fff;
    border: none;
    padding: 0 10px;
    line-height: 55px;
    cursor: pointer
}

footer .barra-footer .menu-footer li.btn-mapa-site a {
    color: #fff
}

footer .barra-footer .logo-daycoval-footer {
    display: block;
    padding-top: 8px
}

footer .barra-footer .logo-daycoval-footer img {
    max-height: 40px
}

footer .barra-footer .mapa-site {
    display: none;
    padding: 20px 0;
    background-color: #004494
}

footer .barra-footer .mapa-site .links-mapa-site li {
    color: #8d93a1
}

footer .barra-footer .mapa-site .links-mapa-site li a {
    color: #8d93a1;
    padding: 0 10px
}

footer .barra-footer .mapa-site .links-mapa-site li.title-mapa-site {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px
}

footer .copyright {
    padding: 15px 0;
    text-align: center
}

footer.light .barra-footer {
    background-color: #ddd
}

footer.light .barra-footer .menu-footer li {
    border-left: #ccc 1px solid;
    border-right: #f5f5f5 1px solid
}

footer.light .barra-footer .menu-footer li:first-child {
    border-left: none
} */

@media (max-width: 1200px) {
    .mbottom-md {
        margin-bottom: 15px
    }
    .mtop-md {
        margin-top: 15px
    }
    header #barra-institucional.acesso-dayconnect.closed-md .links-institucionais .links-institucionais-mob, header #barra-institucional.login.closed-md .links-institucionais .links-institucionais-mob {
        display: block
    }
    header #barra-institucional.acesso-dayconnect.closed-md .links-institucionais ul, header #barra-institucional.login.closed-md .links-institucionais ul {
        display: none
    }
    header #barra-institucional.acesso-dayconnect.closed-md .links-institucionais ul.links-hover-md, header #barra-institucional.login.closed-md .links-institucionais ul.links-hover-md {
        display: block;
        position: absolute;
        width: 250px;
        top: 45px;
        background-color: #ddd
    }
    header #barra-institucional.acesso-dayconnect.closed-md .links-institucionais ul.links-hover-md li, header #barra-institucional.login.closed-md .links-institucionais ul.links-hover-md li {
        float: none;
        font-size: 13px;
        border-right: none;
        border-top: #f5f5f5 1px solid;
        border-bottom: #ccc 1px solid
    }
    header #barra-institucional.acesso-dayconnect.closed-md .links-institucionais ul.links-hover-md li:last-child, header #barra-institucional.login.closed-md .links-institucionais ul.links-hover-md li:last-child {
        border-right: none;
        border-bottom: none
    }
    header #menu-principal nav .links-nav {
        font-size: 12px
    }
    header #menu-principal nav .links-nav.linha-dupla-md {
        line-height: 15px;
        padding-top: 25px
    }
    header #menu-principal nav .links-nav.linha-dupla-md span {
        display: block
    }
}

@media (max-width: 992px) {
    .text-center-sm {
        text-align: center
    }
    header #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais .links-institucionais-mob, header #barra-institucional.login.closed-sm .links-institucionais .links-institucionais-mob {
        display: block
    }
    header #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais ul, header #barra-institucional.login.closed-sm .links-institucionais ul {
        display: none
    }
    header #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais ul.links-hover-sm, header #barra-institucional.login.closed-sm .links-institucionais ul.links-hover-sm {
        display: block;
        position: absolute;
        width: 250px;
        top: 45px;
        background-color: #ddd
    }
    header #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais ul.links-hover-sm li, header #barra-institucional.login.closed-sm .links-institucionais ul.links-hover-sm li {
        float: none;
        font-size: 13px;
        border-right: none;
        border-top: #f5f5f5 1px solid;
        border-bottom: #ccc 1px solid
    }
    header #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais ul.links-hover-sm li:last-child, header #barra-institucional.login.closed-sm .links-institucionais ul.links-hover-sm li:last-child {
        border-right: none;
        border-bottom: none
    }
    header .box-logo .logo {
        margin-top: 20px
    }
    header .box-logo .logo img {
        max-height: 35px
    }
    header #menu-principal nav .links-nav {
        height: 100%;
        padding: 0 10px;
        font-size: 12px
    }
    header #menu-principal nav .links-nav.submenu ul {
        width: 230px
    }
    header #menu-principal nav .links-nav.submenu ul li a {
        padding: 0 10px
    }
    header #menu-principal nav .links-nav.linha-dupla-sm, header #menu-principal nav .links-nav.linha-dupla-md {
        line-height: 15px;
        padding-top: 20px
    }
    header #menu-principal nav .links-nav.linha-dupla-sm span, header #menu-principal nav .links-nav.linha-dupla-md span {
        display: block
    }
}

@media (max-width: 768px) {
    h1.line, h2.line, h3.line, h4.line {
        margin: auto auto 10px auto
    }
    h1.line span, h2.line span, h3.line span, h4.line span {
        background: none;
        padding: 0
    }
    h1.line:before, h2.line:before, h3.line:before, h4.line:before {
        background: none
    }
    .mbottom-xs {
        margin-bottom: 15px
    }
    .mtop-xs {
        margin-top: 15px
    }
    .text-center-xs {
        text-align: center
    }
    /* .modal-daycoval-lightbox.modal-daycoval-lightbox-xs {
        background: #f5f5f5
    }
    .modal-daycoval-lightbox.modal-daycoval-lightbox-xs .modal-daycoval-body {
        top: 0
    }
    .modal-daycoval-lightbox.modal-daycoval-lightbox-xs .modal-daycoval-body .modal-daycoval {
        margin-top: 30px;
        max-width: 100%
    } */
    .btn-tel {
        padding: 10px 15px;
        border: #ddd 1px solid
    }
    .alert.alert-empty h3 {
        font-size: 18px
    }
    .alert.alert-empty i {
        font-size: 30px
    }
    header #barra-institucional {
        height: auto;
        background-color: transparent;
        border-bottom: #ddd 1px solid
    }
    header #barra-institucional .container {
        padding: 0
    }
    header #barra-institucional .links-institucionais {
        float: none;
        position: relative
    }
    header #barra-institucional .links-institucionais ul {
        display: none;
        clear: both;
        position: static;
        width: 100%;
        background-color: #ddd
    }
    header #barra-institucional .links-institucionais ul li {
        float: none;
        font-size: 13px;
        border-right: none;
        border-top: #f5f5f5 1px solid;
        border-bottom: #ccc 1px solid
    }
    header #barra-institucional .links-institucionais ul li:last-child {
        border-right: none;
        border-bottom: none
    }
    header #barra-institucional .links-institucionais ul li:first-child {
        background-color: transparent
    }
    header #barra-institucional .links-institucionais ul.links-hover-xs {
        display: block
    }
    header #barra-institucional .links-institucionais .links-institucionais-mob {
        width: 100%;
        background-color: #ccc;
        display: block
    }
    header #barra-institucional .links-institucionais .links-institucionais-mob:hover {
        text-decoration: underline
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais, header #barra-institucional.login .links-institucionais {
        float: none;
        position: relative
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais ul, header #barra-institucional.login .links-institucionais ul {
        position: absolute;
        width: 250px;
        top: 45px;
        background-color: #ddd
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais ul li, header #barra-institucional.login .links-institucionais ul li {
        float: none;
        font-size: 13px;
        border-right: none;
        border-top: #f5f5f5 1px solid;
        border-bottom: #ccc 1px solid
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais ul li:last-child, header #barra-institucional.login .links-institucionais ul li:last-child {
        border-right: none;
        border-bottom: none
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais .links-institucionais-mob, header #barra-institucional.login .links-institucionais .links-institucionais-mob {
        width: 100%
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais .links-institucionais-mob:hover, header #barra-institucional.login .links-institucionais .links-institucionais-mob:hover {
        text-decoration: underline
    }
    header #barra-institucional.acesso-dayconnect .dayconnect, header #barra-institucional.login .dayconnect {
        float: none;
        padding: 0;
        clear: both;
        position: static;
        right: 0;
        top: 0
    }
    header #barra-institucional.acesso-dayconnect .dayconnect .link-dayconnect, header #barra-institucional.login .dayconnect .link-dayconnect {
        display: none
    }
    header #barra-institucional.acesso-dayconnect .dayconnect .link-dayconnect-mob, header #barra-institucional.login .dayconnect .link-dayconnect-mob {
        display: block
    }
    header #barra-institucional.acesso-dayconnect .dayconnect .form-group, header #barra-institucional.login .dayconnect .form-group {
        display: none;
        margin-top: 45px;
        margin-right: 15px;
        background-color: #0688c9;
        padding: 15px 10px 10px
    }
    header #barra-institucional.acesso-dayconnect .dayconnect .form-group form, header #barra-institucional.login .dayconnect .form-group form {
        margin-bottom: 5px
    }
    header #barra-institucional.acesso-dayconnect .dayconnect.open, header #barra-institucional.login .dayconnect.open {
        position: absolute
    }
    header #barra-institucional.acesso-dayconnect .dayconnect.open .form-group, header #barra-institucional.login .dayconnect.open .form-group {
        display: block
    }
    header #barra-institucional.acesso-dayconnect .dayconnect.open hr.divisao-login, header #barra-institucional.login .dayconnect.open hr.divisao-login {
        display: block
    }
    header #barra-institucional.acesso-dayconnect .dayconnect.open .title-dayconnect, header #barra-institucional.login .dayconnect.open .title-dayconnect {
        display: block;
        text-transform: uppercase;
        text-align: center;
        font-size: 12px;
        color: #ddd;
        display: block;
        margin-bottom: 5px
    }
    header #barra-institucional.acesso-dayconnect .dayconnect.open .abrir-conta, header #barra-institucional.login .dayconnect.open .abrir-conta {
        margin: 0
    }
    header #barra-institucional.acesso-dayconnect .dayconnect.open .abrir-conta.login, header #barra-institucional.login .dayconnect.open .abrir-conta.login {
        margin-bottom: 5px;
        background-color: #2c466e;
        color: #ddd
    }
    header #barra-institucional.acesso-dayconnect .abrir-conta, header #barra-institucional.login .abrir-conta {
        float: none;
        height: 35px;
        line-height: 35px;
        clear: both;
        margin-left: 2px;
        text-align: center;
        background-color: #002052
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais {
        float: left
    }
    header #barra-institucional.login .links-institucionais {
        float: left
    }
    header #barra-institucional.login .user-logado .saudacao {
        color: #fff
    }
    header #barra-institucional.light .links-institucionais ul li {
        border-right: none
    }
    header #barra-institucional.light .links-institucionais ul li:first-child {
        background-color: transparent
    }
    header #barra-institucional.closed-xs .links-institucionais ul {
        display: none
    }
    header.menu-mob-left #barra-institucional {
        background-color: transparent;
        border-bottom: none;
        margin: 0 15px
    }
    header .box-logo .logo {
        margin-top: 15px
    }
    header .box-logo .logo img {
        max-height: 25px;
        margin-left: 15px;
    }
    header .box-logo .btn-menu-mob {
        display: block;
        float: right;
        margin-right: 0
    }
    header #menu-principal {
        float: right
    }
    header #menu-principal .container {
        padding: 0
    }
    header #menu-principal .btn-destaque-menu {
        line-height: 60px;
        margin-right: 0;
        padding: 0 15px;
        left: -15px;
        position: relative
    }
    header #menu-principal .btn-destaque-menu span {
        display: none
    }
    header #menu-principal .btn-destaque-menu i {
        margin-right: 0;
        font-size: 20px
    }
    header #menu-principal .usuario-logado {
        display: none
    }
    header #menu-principal nav {
        display: none;
        height: auto;
        clear: both;
        border: none;
        border-top: #ddd 1px solid
    }
    header #menu-principal nav .links-nav {
        float: none;
        border: none;
        border-bottom: #ddd 1px solid;
        border-top: #fff 1px solid;
        margin: auto;
        line-height: 45px;
        text-align: left;
        font-size: 13px;
        padding: 0 20px;
        height: auto
    }
    header #menu-principal nav .links-nav.submenu {
        padding: 0
    }
    header #menu-principal nav .links-nav.submenu .links-nav-submenu {
        height: 45px;
        padding: 0 20px
    }
    header #menu-principal nav .links-nav.submenu ul {
        background-color: #f5f5f5;
        position: relative;
        width: 100%;
        clear: both
    }
    header #menu-principal nav .links-nav.submenu ul li {
        border: none;
        border-bottom: #ddd 1px solid;
        border-top: #fff 1px solid;
        width: 100%;
        background-color: transparent;
        line-height: normal
    }
    header #menu-principal nav .links-nav.submenu ul li a {
        color: #999;
        padding: 0 20px
    }
    header #menu-principal nav .links-nav.submenu ul li a:hover {
        text-decoration: underline
    }
    header #menu-principal nav .links-nav.submenu ul li:last-child {
        border-bottom: none
    }
    header #menu-principal nav .links-nav.linha-dupla, header #menu-principal nav .links-nav.linha-dupla-md, header #menu-principal nav .links-nav.linha-dupla-sm {
        line-height: 45px;
        padding-top: 0
    }
    header #menu-principal nav .links-nav.linha-dupla span, header #menu-principal nav .links-nav.linha-dupla-md span, header #menu-principal nav .links-nav.linha-dupla-sm span {
        display: inline-block
    }
    header #menu-principal nav .links-nav:last-child {
        border-bottom: none
    }
    header #menu-principal.full-menu nav .links-nav {
        position: static
    }
    header #menu-principal.full-menu nav .links-nav.submenu .submenu-full {
        padding: 0;
        position: relative
    }
    header #menu-principal.full-menu nav .links-nav.submenu .submenu-full ul {
        display: block;
        padding: 0
    }
    header #menu-principal.full-menu nav .links-nav.submenu .submenu-full ul li a {
        padding: 0 20px 0 30px;
        height: 40px;
        line-height: 40px;
        border-bottom: #ddd 1px solid
    }
    header #menu-principal.full-menu nav .links-nav.submenu .submenu-full ul li.title-submenu {
        background-color: #fff;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0
    }
    header #menu-principal.full-menu nav .links-nav.submenu .submenu-full ul li.title-submenu a {
        padding-left: 20px
    }
    header #menu-principal.full-menu nav .links-nav.submenu .submenu-full .row {
        margin: 0
    }
    header #menu-principal.full-menu nav .links-nav.submenu .submenu-full .row div {
        padding: 0
    }
    header.dark .box-logo .btn-menu-mob {
        background-color: #002052;
        color: #fff
    }
    header.dark #menu-principal nav {
        border: none
    }
    header.dark #menu-principal nav .links-nav {
        border: none
    }
    header.menu-mob-left {
        position: fixed;
        width: 90%;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin-left: 90%;
        transition: margin 0.1s linear;
        transform: translateX(-100%);
        padding-top: 60px
    }
    header.menu-mob-left .box-logo {
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        padding-left: 15px;
        z-index: 9999;
        background-color: #f5f5f5
    }
    header.menu-mob-left #menu-principal {
        display: block;
        float: none;
        padding-top: 5px
    }
    header.menu-mob-left #menu-principal .usuario-logado {
        display: block;
        float: none;
        clear: both;
        height: 55px
    }
    header.menu-mob-left #menu-principal .usuario-logado .btn-sair {
        float: right
    }
    header.menu-mob-left #menu-principal nav {
        display: block;
        float: none
    }
    header.menu-mob-left #menu-principal .btn-destaque-menu {
        float: none;
        line-height: 45px;
        margin-right: 0;
        left: 0
    }
    header.menu-mob-left #menu-principal .btn-destaque-menu span {
        display: inline
    }
    header.menu-mob-left #menu-principal .btn-destaque-menu i {
        margin-right: 5px;
        font-size: 14px
    }
    header.menu-mob-left.dark .box-logo {
        background-color: #002052
    }
    header.menu-mob-left.dark .box-logo .btn-menu-mob {
        background-color: #002052;
        color: #fff
    }
    /* .barra-footer .mapa-site .links-mapa-site {
        margin-top: 15px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        width: 100%;
        border-bottom: #3c5c8e 1px solid
    }
    .barra-footer .mapa-site .links-mapa-site li a {
        line-height: 25px;
        display: block
    }
    .barra-footer .mapa-site .links-mapa-site li.title-mapa-site {
        border: none
    }
    .barra-footer .mapa-site .links-mapa-site.first {
        margin-top: 0
    }
    .barra-footer .mapa-site .links-mapa-site.last {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0
    } */
}

@media (max-width: 480px) {
    .mbottom-xxs {
        margin-bottom: 15px
    }
    .mtop-xxs {
        margin-top: 15px
    }
    /* .modal-daycoval-lightbox.modal-daycoval-lightbox-xxs {
        background: #f5f5f5
    }
    .modal-daycoval-lightbox.modal-daycoval-lightbox-xxs .modal-daycoval-body {
        top: 0
    }
    .modal-daycoval-lightbox.modal-daycoval-lightbox-xxs .modal-daycoval-body .modal-daycoval {
        margin-top: 30px;
        max-width: 480px
    } */
    .chamada-fale-canto {
        width: 100%;
        right: 0;
        text-align: center
    }
    .chamada-fale-canto h3 {
        margin: 0
    }
    header #barra-institucional {
        height: auto;
        background-color: #f5f5f5
    }
    header #barra-institucional .container {
        padding: 0
    }
    header #barra-institucional.login .user-logado {
        float: none;
        width: 100%;
        padding: 0
    }
    header #barra-institucional.login .user-logado .saudacao {
        color: #666
    }
    header #barra-institucional.login .user-logado form {
        float: left
    }
    header #barra-institucional.login .user-logado .btnLogout {
        height: 45px;
        line-height: 45px;
        padding: 0 10px;
        margin-left: 10px;
        background-color: #2c466e;
        float: left;
        color: #f5f5f5
    }
    header #barra-institucional.login .user-logado .btnLogout:hover {
        background-color: #004494;
        cursor: pointer
    }
    header #barra-institucional.login .user-logado .form-login {
        float: right
    }
    header #barra-institucional.login .user-logado .link-dayconnect {
        padding: 0 10px;
        margin-right: 5px;
        height: 45px;
        line-height: 45px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase
    }
    header #barra-institucional.login .links-institucionais ul li:first-child {
        background-color: #ddd
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais, header #barra-institucional.login .links-institucionais {
        float: none;
        position: relative
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais ul, header #barra-institucional.login .links-institucionais ul {
        clear: both;
        position: static;
        width: 100%
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais ul li, header #barra-institucional.login .links-institucionais ul li {
        float: none
    }
    header #barra-institucional.acesso-dayconnect .links-institucionais .links-institucionais-mob, header #barra-institucional.login .links-institucionais .links-institucionais-mob {
        width: 100%;
        background-color: #ddd;
        display: none
    }
    header #barra-institucional.acesso-dayconnect .dayconnect, header #barra-institucional.login .dayconnect {
        position: relative;
        right: auto;
        top: auto
    }
    header #barra-institucional.acesso-dayconnect .dayconnect .link-dayconnect-mob, header #barra-institucional.login .dayconnect .link-dayconnect-mob {
        display: block;
        width: 100%;
        left: 0;
        text-align: center;
        position: relative;
        margin-bottom: 0
    }
    header #barra-institucional.acesso-dayconnect .dayconnect .form-group, header #barra-institucional.login .dayconnect .form-group {
        position: relative;
        width: 100%;
        padding: 10px 5%;
        border-top: #f5f5f5 1px solid;
        top: 0;
        right: 0;
        margin-top: 0
    }
    header #barra-institucional.acesso-dayconnect .dayconnect .form-group input, header #barra-institucional.login .dayconnect .form-group input {
        width: 19.5%;
        padding: 0 1%;
        margin: 0 0 0 0.5%
    }
    header #barra-institucional.acesso-dayconnect .dayconnect .form-group input:first-child, header #barra-institucional.login .dayconnect .form-group input:first-child {
        margin: 0
    }
    header #barra-institucional.acesso-dayconnect .dayconnect .form-group input[type="submit"], header #barra-institucional.login .dayconnect .form-group input[type="submit"] {
        width: 17.5%;
        margin-right: 0
    }
    header #barra-institucional.acesso-dayconnect .dayconnect.open, header #barra-institucional.login .dayconnect.open {
        position: relative
    }
    header #barra-institucional.acesso-dayconnect .abrir-conta, header #barra-institucional.login .abrir-conta {
        margin-left: 0
    }
    header #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais .links-institucionais-mob, header #barra-institucional.acesso-dayconnect.closed-md .links-institucionais .links-institucionais-mob, header #barra-institucional.login.closed-sm .links-institucionais .links-institucionais-mob, header #barra-institucional.login.closed-md .links-institucionais .links-institucionais-mob {
        display: none
    }
    header #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais ul, header #barra-institucional.acesso-dayconnect.closed-md .links-institucionais ul, header #barra-institucional.login.closed-sm .links-institucionais ul, header #barra-institucional.login.closed-md .links-institucionais ul {
        display: none
    }
    header #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais ul.links-hover-md, header #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais ul.links-hover-sm, header #barra-institucional.acesso-dayconnect.closed-md .links-institucionais ul.links-hover-md, header #barra-institucional.acesso-dayconnect.closed-md .links-institucionais ul.links-hover-sm, header #barra-institucional.login.closed-sm .links-institucionais ul.links-hover-md, header #barra-institucional.login.closed-sm .links-institucionais ul.links-hover-sm, header #barra-institucional.login.closed-md .links-institucionais ul.links-hover-md, header #barra-institucional.login.closed-md .links-institucionais ul.links-hover-sm {
        display: none;
        width: 100% !important;
        top: 0 !important;
        position: relative !important
    }
    header #barra-institucional.light {
        background-color: #f5f5f5
    }
    header #barra-institucional.light .links-institucionais ul {
        border-bottom: #ddd 3px solid
    }
    header #barra-institucional.light .links-institucionais ul li {
        border-right: none;
        background-color: #f5f5f5
    }
    header #barra-institucional.light .links-institucionais ul li:first-child {
        background-color: #f5f5f5
    }
    header #barra-institucional.light .links-institucionais ul li:first-child a {
        padding: 0 14px
    }
    header #barra-institucional.light .links-institucionais ul li:first-child a i {
        font-size: 25px;
        line-height: 45px;
        float: left;
        margin-right: 5px
    }
    header.menu-mob-left #barra-institucional .links-institucionais .links-institucionais-mob {
        display: block
    }
    header.menu-mob-left #barra-institucional .links-institucionais ul {
        display: block
    }
    header.menu-mob-left #barra-institucional.acesso-dayconnect .dayconnect, header.menu-mob-left #barra-institucional.login .dayconnect {
        padding-top: 3px
    }
    header.menu-mob-left #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais ul.links-hover-md, header.menu-mob-left #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais ul.links-hover-sm, header.menu-mob-left #barra-institucional.acesso-dayconnect.closed-md .links-institucionais ul.links-hover-md, header.menu-mob-left #barra-institucional.acesso-dayconnect.closed-md .links-institucionais ul.links-hover-sm, header.menu-mob-left #barra-institucional.login.closed-sm .links-institucionais ul.links-hover-md, header.menu-mob-left #barra-institucional.login.closed-sm .links-institucionais ul.links-hover-sm, header.menu-mob-left #barra-institucional.login.closed-md .links-institucionais ul.links-hover-md, header.menu-mob-left #barra-institucional.login.closed-md .links-institucionais ul.links-hover-sm {
        display: block
    }
    header.menu-mob-left #barra-institucional.acesso-dayconnect.closed-sm .links-institucionais .links-institucionais-mob, header.menu-mob-left #barra-institucional.acesso-dayconnect.closed-md .links-institucionais .links-institucionais-mob, header.menu-mob-left #barra-institucional.login.closed-sm .links-institucionais .links-institucionais-mob, header.menu-mob-left #barra-institucional.login.closed-md .links-institucionais .links-institucionais-mob {
        display: block
    }
    footer .barra-footer .logo-daycoval-footer {
        padding-top: 15px
    }
    footer .barra-footer .logo-daycoval-footer img {
        max-height: 30px
    }
}