@mixin font-black {
  color: #000;
  margin-bottom: 0;
}


.font15-regular-black {
  @include font-black;
  font-family: 'Muli-Regular';
  font-size: 15px;
}

.font16-bold-black {
  @include font-black;
  font-family: 'Muli-Bold';
  font-size: 16px;
}


@media (max-width: 479px) {
  .font15-regular-black {
    padding: 0 15px;
  }
}
