@font-face {
    font-family: 'Muli-Regular';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Muli-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Muli-Light';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Muli-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Muli-Bold';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Muli-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Muli-ExtraBold';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Muli-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Muli-SemiBold';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Muli-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Muli-Black';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Muli-Black.ttf') format('truetype');
}
