@import "../../assets/sass/button";
@import "../../assets/sass/typography";
@import "../../assets/sass/variables";
@import "../../assets/sass/mixin";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';


//colors
.blue {
  color: $cerulean;
}

// MATERIAL DESIGN
.example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

//Modal Generica



@media only screen and (max-width: 640px) {
  .modal-content {
    border: none;
    width: 340px !important;
  }
}


.example-full-width {
  width: 100%;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  font-size: 16px;
  font-family: 'Muli-Bold';
}

.mat-form-field-infix {
  padding: 0!important;
}

.mat-form-field-label-wrapper {
  font-size: 18px;
  font-family: 'Muli-Regular';
  top: -.5em;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: .2em 0;
}


@media (max-width: 767px) {
  .mobile-text-center {
    width: 100%;
    text-align: center;
  }

  .btn-mobile {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }

  //margin top and bottom
  .mb-xs {
    margin-bottom: 10px;
  }

  .mb-mt-xs {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .title-p {
    text-align: center;
    margin-top: 20px;
    padding: 0 15px;
  }

  .title-p-fim {
    text-align: center;
    margin-top: 20px;
  }

  .duvidas{
    margin-top: 20px;
    text-align: center;
  }

  h1 {
    font-family: 'Muli-Black';
    font-size: 24px;
    color: #1b438b;
    margin-bottom: 0;
  }

  .app {
    margin-top: 15px;
  }

  .mat-slide-toggle-content,
  .mat-radio-label-content {
    font-size: 12px !important;
  }


  @media (max-width: 375px) {
    .alinha-espaco-iphone {
      height: 80%;
    }
  }

  .align-items-xs-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }

  .d-xs-none {
    display: none;
  }

}


/*******************************************************************************/
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1b438b ;
}

.mat-input-element {
  caret-color: #1b438b ;
}

.mat-progress-bar-fill::after {
  background-color: #1b438b ;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #ffffff ;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1b438b ;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
    background-color: #1b438b ;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1b438b ;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1b438b ;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1b438b ;
}

.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label,
.mat-primary .mat-slider-track-fill {
    background-color: #1b438b ;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
    background-color: #1b438b ;;
    color: #fff
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #1b438b ;
}

.mat-tab-group.mat-background-primary .mat-tab-header,
.mat-tab-group.mat-background-primary .mat-tab-links,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links {
    background-color: #1b438b ;
}

.mat-toolbar.mat-primary {
  background: #1b438b ;;
  color: #fff
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1b438b ;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #1b438b ;
}

.mat-badge-content {
  color: #fff;
  background: #1b438b ;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
    color: #1b438b ;
}

.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: #1b438b ;
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
    background-color: #1b438b ;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1b438b ;;
  color: #fff
}

.mat-calendar-body-selected {
  background-color: #1b438b ;;
  color: #fff
}

.mat-datepicker-toggle-active {
  color: #1b438b ;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #1b438b ;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1b438b ;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1b438b ;
}

.mat-focused .mat-form-field-required-marker {
  color: #1b438b
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #1b438b ;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(27, 67, 139, .54)
}

//inputs warnings
.mat-form-field.mat-focused.mat-form-field-invalid
  .mat-form-field-ripple {
  background-color: #f44336 ;
}



.mat-form-field.mat-focused.mat-form-field-invalid
 .mat-form-field-label {
  color: #f44336 ;
}
