#wrapper, .container-inner {
    min-width: 320px;
}

/*Dispositivos Extra Pequenos
==================================================*/

@media only screen and (max-width: 480px) {
    /* layout */
    .content, .main, .main-inner {
        background-image: none!important;
        padding: 0!important;
    }
    /* base */
    .pad {
        padding: 15px 15px 5px;
    }
    /* header */
    .site-title {
        font-size: 32px;
    }
}


/*Dispositivos Pequenos e Médios
==================================================*/

@media only screen and (max-width: 767px) {
    /* layout */
    .container {
        padding: 0;
    }
    /* base */
    .pad {
        padding: 20px 20px 10px;
    }
    /* header */
    #header {
        padding-bottom: 0;
    }
    /* Nav header common */
    #nav-header.nav-container {
        border-left: 0;
        border-right: 0;
    }
    #nav-header .container {
        padding: 0;
    }
    #nav-header .nav {
        padding-bottom: 20px;
    }
    #nav-header .nav li a {
        color: #fff;
        color: rgba(255, 255, 255, 0.8);
        border-top: 1px solid rgba(255, 255, 255, 0.06);
    }
    /* level 1 */
    #nav-header .nav li>a:hover {
        background: rgba(0, 0, 0, 0.15);
        color: #fff;
    }
    #nav-header .nav li.current_page_item>a, #nav-header .nav li.current-menu-item>a, #nav-header .nav li.current-post-parent>a {
        background: rgba(0, 0, 0, 0.15);
        color: #fff;
    }
    .nav {
        font-weight: normal;
    }
    .nav-container {
        text-transform: none;
    }
    .nav-toggle, .nav-text {
        display: block;
    }
    .nav-wrap {
        position: relative;
        float: left;
        width: 100%;
        height: 0;
        overflow: hidden;
    }
    .nav-wrap.transition {
        -webkit-transition: height 0.35s ease;
        -moz-transition: height 0.35s ease;
        -o-transition: height 0.35s ease;
        transition: height 0.35s ease;
    }
    .expand .nav-wrap {
        height: auto;
    }
    /* common */
    .nav {
        float: left;
        width: 100%;
    }
    .nav li a {
        line-height: 20px;
        display: block;
        padding: 8px 20px;
    }
    .nav li li a {
        padding-left: 15px;
        padding-right: 15px;
    }
    /* dropdown arrows */
    .nav li>a:after {
        content: '\f0d7';
        opacity: 0.5;
        margin-left: 6px;
    }
    .nav>li>a:after {
        content: '\f0d7';
        font-size: 0.8em;
    }
    .nav li>a:only-child:after {
        content: '';
    }
    /* level 1 */
    .nav>li {
        font-size: 0.9em;
        font-weight: normal;
        text-transform: uppercase;
    }
    .nav li>a:hover, .nav li.current_page_item>a, .nav li.current-menu-item>a, .nav li.current-post-parent>a {
        color: #fff;
    }
    /* level 2 & 3 */
    .nav ul {
        display: block!important;
        margin-left: 40px;
    }
    .nav ul li {
        font-size: 0.8em;
        font-weight: 300;
    }
    .nav ul li a {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    /* Search */
    .toggle-search {
        right: auto;
        left: 0;
        top: 0;
        -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1);
        box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1);
    }
    .dayconnect-expand {
        left: 0;
        right: auto;
        top: 50px;
        width: 320px;
    }
    /* footer */
    #footer-bottom .pad {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    #footer-bottom .one-half.last {
        margin-bottom: 0;
    }
    #footer-bottom {
        text-align: center;
    }
    #footer-bottom #footer-logo {
        margin-left: auto;
        margin-right: auto;
    }
    #footer-bottom .social-links {
        float: none;
    }
    #footer-bottom .social-links li {
        margin-left: 4px;
        margin-right: 4px;
    }
    #nav-footer .nav li a {
        color: #999;
        color: rgba(0, 0, 0, 0.8);
        border-top: 1px solid #f2f2f2;
    }
    /* level 1 */
    #nav-footer .nav li>a:hover {
        background: #f2f2f2;
        color: #444;
    }
    #nav-footer .nav li.current_page_item>a, #nav-footer .nav li.current-menu-item>a, #nav-footer .nav li.current-post-parent>a {
        background: #f2f2f2;
        color: #444;
    }
    /* top-menu */
    #nav-topbar #menu-topbar-menu i {
        display: none;
    }
    /* common */
    #nav-topbar .nav li a {
        color: #fff;
        color: rgba(255, 255, 255, 0.8);
        border-top: 1px solid rgba(255, 255, 255, 0.06);
    }
    /* level 1 */
    #nav-topbar .nav li>a:hover {
        background: rgba(0, 0, 0, 0.15);
        color: #fff;
    }
    #nav-topbar .nav li.current_page_item>a, #nav-topbar .nav li.current-menu-item>a, #nav-topbar .nav li.current-post-parent>a {
        background: rgba(0, 0, 0, 0.15);
        color: #fff;
    }
    /* common */
    .site-title {
        left: 50%;
        margin-left: -132px !important;
    }
}

@media (max-width: 979px) {
    .content-home-area {
        padding: 150px 0 267px;
    }
    .site-title {
        float: none;
    }
    /*Footer*/
    #footer {
        overflow: hidden;
    }
    #footer-logo {
        margin-bottom: 0;
        text-align: center;
    }
    #footer-social-media {
        text-align: center;
    }
    #footer-social-media .social-links {
        float: none;
    }
    /*Carousel home*/
    .carousel-landing-page .caption-carousel-home {
        width: 55%;
    }
    .carousel-landing-page {
        margin-bottom: -303px;
        position: relative;
    }
}

/*Dispositivos Grandes
==================================================*/

@media only screen and (min-width: 768px) {
    .nav-wrap {
        height: auto!important;
    }
    /* common */
    .nav {
        font-size: 0;
        position: relative;
    }
    .nav li a {
        color: #ccc;
        display: block;
        line-height: 20px;
    }
    /* dropdown arrows */
    .nav li>a:after {
        content: "\f0da";
        float: right;
        opacity: 0.5;
    }
    .nav>li>a:after {
        bottom: 0;
        content: "\f107";
        font-size: 1.5em;
        left: 50%;
        margin-left: -4px;
        position: absolute;
    }
    .nav li>a:only-child:after {
        content: "";
        margin: 0;
    }
    /* level 1 */
    .nav>li {
        border-right: 1px solid #999;
        display: inline-block !important;
        position: relative;
    }
    .nav>li>a {
        padding: 10px 14px;
    }
    .nav>li>a:hover, .nav>li:hover>a {
        background: #777;
    }
    .nav li>a:hover, .nav li:hover>a, .nav li.current_page_item>a, .nav li.current-menu-item>a, .nav li.current-menu-ancestor>a, .nav li.current-post-parent>a {
        color: #fff;
    }
    /* level 2 & 3 */
    .nav li:hover>ul {
        display: block;
    }
    .nav ul {
        display: none;
        background: #777;
        position: absolute;
        left: 0;
        top: 50px;
        width: 180px;
        padding: 10px 0;
        z-index: 2;
        -webkit-transform: translateZ(0);
        -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    }
    .nav ul li {
        font-size: 11px;
        position: relative;
        display: block;
        padding: 0;
        text-transform: uppercase;
    }
    .nav ul li a {
        padding: 10px 20px;
    }
    .nav ul li:last-child {
        border-bottom: 0!important;
    }
    /* level 3 */
    .nav ul ul {
        position: absolute;
        top: -10px;
        left: 180px;
    }
    /* Top bar common */
    #nav-topbar .nav i {
        font-size: 1.8em;
        margin-right: 5px;
    }
    #nav-topbar .nav li a {
        color: #fff;
        font-size: 11px;
        text-transform: uppercase;
    }
    /* level 1 */
    #nav-topbar .nav>li {
        border-right: none;
    }
    #nav-topbar .nav>li a:focus {
        background-color: inherit !important;
    }
    #nav-topbar .nav>li>a:hover, #nav-topbar .nav>li:hover>a {
        background-color: rgba(0, 0, 0, 0.1);
    }
    #nav-topbar .nav li>a:hover, #nav-topbar .nav li:hover>a, #nav-topbar .nav li.current_page_item>a, #nav-topbar .nav li.current-menu-item>a, #nav-topbar .nav li.current-menu-ancestor>a, #nav-topbar .nav li.current-post-parent>a {
        color: #fff;
    }
    /* level 2 & 3 */
    /* #nav-topbar .nav ul {
                background: #26272b url(img/opacity-10.png) repeat;
            } */
    #nav-topbar .nav ul li {
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.06);
        -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.06);
    }
    #nav-topbar .nav ul li:last-child {
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    /* Footer common */
    #footer .nav li>a:after {
        content: "\f0da";
    }
    #footer .nav>li>a:after {
        content: "\f106";
        position: absolute;
        top: -3px;
    }
    #footer .nav li>a:only-child:after {
        content: "";
    }
    #nav-footer .nav {
        text-align: center;
    }
    #nav-footer .nav li a {
        color: #999;
    }
    /* level 1 */
    #nav-footer .nav>li>a {
        font-size: 11px;
        padding: 12px 18px;
        text-transform: uppercase;
    }
    #nav-footer .nav>li {
        border-right: 0;
    }
    #nav-footer .nav>li:first-child {
        border-left: 0;
    }
    #nav-footer .nav>li>a:hover, #nav-footer .nav>li:hover>a {
        background: #f0f0f0;
    }
    #nav-footer .nav li>a:hover, #nav-footer .nav li:hover>a, #nav-footer .nav li.current_page_item>a, #nav-footer .nav li.current-menu-item>a, #nav-footer .nav li.current-menu-ancestor>a, #nav-footer .nav li.current-post-parent>a {
        color: #666;
    }
    /* level 2 & 3 */
    #nav-footer .nav ul {
        background: #f0f0f0;
        bottom: 45px;
        padding: 0;
        top: auto;
        text-align: left;
        -webkit-box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.05);
        box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.05);
    }
    #nav-footer .nav ul li {
        border-bottom: 1px solid #e4e4e4;
    }
    #nav-footer .nav ul ul {
        top: -10px;
    }
    /* level 3 */
    #nav-footer .nav ul ul {
        position: absolute;
        top: auto;
        bottom: -10px;
    }
    /* common */
    #nav-header .nav {}
    #nav-header .nav li a {
        color: #fff;
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.6em;
        text-transform: uppercase;
    }
    /* level 1 */
    #nav-header .nav>li {
        border-right: none;
    }
    #nav-header .nav>li>a:hover, #nav-header .nav>li:hover>a {
        background: rgba(0, 0, 0, 0.1);
    }
    #nav-header .nav li>a:hover, #nav-header .nav li:hover>a, #nav-header .nav li.current_page_item>a, #nav-header .nav li.current-menu-item>a, #nav-header .nav li.current-menu-ancestor>a, #nav-header .nav li.current-post-parent>a {
        color: #fff;
    }
    /* level 2 & 3 */
    /* #nav-header .nav ul {
            background: #33363b url(img/opacity-10.png) repeat;
        } */
    #nav-header .nav ul li {
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.06);
        -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.06);
    }
    #nav-header .nav ul li:last-child {
        box-shadow: none;
        -webkit-box-shadow: none;
    }
}


@media (max-width: 576px) {
    .container {
        padding: 0 15px;
    }
}