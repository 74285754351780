/* You can add global styles to this file, and also import other style files */
.modal-dialog:not(.modal-mobile):not(.modal-video) {
  margin-top: 25vh !important;
  width: 400px !important;
}

.modal-autenticacao {
  max-width: none;
  width: 800px;
  margin-left: 25% !important;
}

.modal-capturada {
  max-width: none;
  width: 800px;
}

.modal-mobile {
  margin-top: 25vh !important;
  max-width: none !important;
  width: 80em !important;
}

div.links-nav.submenu ul {
  z-index: 9999 !important;
}


.modal-video {
  margin-left: 25% !important;
  margin-top: 4% !important;
  .modal-content{
    height: 0px;
  }
}

.loader-modal{
  background-color: transparent;
  .modal-content{
    border: none !important;
  }
}

.row:before, .row:after {display: none !important;}
